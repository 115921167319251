type GTagArg = Array<any>;

type GTag = (...args: GTagArg) => void;

type GrowthbookEvent = {
   eventName: string;
   experimentId: string;
   variationId: string;
};

declare global {
   interface Window {
      gtag?: GTag;
      dataLayer?: GTagArg;
   }
}

function gtag(...args: GTagArg) {
   // eslint-disable-next-line no-console
   console.log('gtag', gtag);
   if (window.gtag) {
      window.gtag(...args);
   }
}

export const trackGA4GrowthbookEvent = (trackData: GrowthbookEvent) => {
   gtag('event', trackData.eventName, {
      'experiment_id': trackData.experimentId,
      'variation_id': trackData.variationId,
   });
};

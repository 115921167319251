import { trackEventInPiwik } from 'Shared/Analytics/Piwik/PiwikEvent';
import { trackGA4GrowthbookEvent } from 'Shared/Analytics/trackGA4Events';
import { type Experiment, GrowthBook, type Result } from '@growthbook/growthbook';
import 'Shared/global.d';

const GROWTHBOOK_DEBUG = new URLSearchParams(window.location.search).get('gbDebug') === 'true';

const canGroupLogs = 'group' in console && 'groupCollapsed' in console && 'groupEnd' in console;

const logger = canGroupLogs
   ? /* eslint-disable no-console */
     (msg: string, ctx: Record<string, unknown>) => {
        console.groupCollapsed(`[GrowthBook] ${msg}`);
        console.log(ctx);
        console.groupEnd();
     }
   : (msg: string, ctx: Record<string, unknown>) => {
        console.log(`[GrowthBook] ${msg}`, ctx);
     };
/* eslint-enable no-console */

export async function handleServerExperimentViewed() {
   if (!App.gb) {
      // Growthbook is not enabled on this pagetype. Currently only enabled on CartPage and GuidePage.
      return;
   }

   const growthbook = await getGrowthbook();

   if (App.gb.viewedExperiments) {
      growthbook.setDeferredTrackingCalls(App.gb.viewedExperiments);
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- FIXME: Promises must be awaited, end with...
      growthbook.fireDeferredTrackingCalls();
   }
}

/**
 * @knipignore
 */
export async function isFeatureOn(feature: string) {
   const growthbook = await getGrowthbook();
   return growthbook.isOn(feature);
}

let growthbookInstance: GrowthBook | null = null;

export async function getGrowthbook() {
   if (growthbookInstance) {
      return growthbookInstance;
   }

   const growthbook = new GrowthBook({
      apiHost: 'https://cdn.growthbook.io',
      clientKey: 'sdk-eWD3DuVIUI93h5o',
      enableDevMode: GROWTHBOOK_DEBUG,
      debug: GROWTHBOOK_DEBUG,
      backgroundSync: true,
      log: logger,
      trackingCallback: (experiment: Experiment<any>, result: Result<any>) => {
         const experimentId = experiment.key;
         const variationId = result.key;

         growthbook.log('Experiment Viewed', {
            experimentId,
            variationId,
            fullDetails: {
               experiment,
               result,
            },
         });

         trackGA4GrowthbookEvent({
            eventName: 'experiment_viewed',
            experimentId,
            variationId,
         });

         trackEventInPiwik({
            eventCategory: 'Experiment Viewed',
            eventAction: variationId,
            eventName: experimentId,
         });
      },
   });

   if (App.gb?.attributes) {
      await growthbook.setAttributes(App.gb?.attributes);
   }

   if (App.gb?.features) {
      await growthbook.setPayload({
         features: App.gb.features,
      });
   }

   if (App.gb?.forcedFeatures) {
      const featureMap = new Map(Object.entries(App.gb.forcedFeatures));
      await growthbook.setForcedFeatures(featureMap);
   }

   if (App.gb?.forcedVariations) {
      await growthbook.setForcedVariations(App.gb.forcedVariations);
   }

   growthbookInstance = growthbook;

   return growthbook;
}

export function AddVercelToolBar() {
   const toolbar = App.toolbar;
   if (!toolbar) {
      return;
   }

   // eslint-disable-next-line @typescript-eslint/no-floating-promises -- FIXME: Promises must be awaited, end with...
   Promise.all([
      import('react-dom/client'),
      import('Shared/chakra-provider'),
      import('Shared/react-query-initialize'),
      import('@ifixit/toolbar'),
   ]).then(
      ([
         { createRoot },
         { ChakraDefaultProvider },
         { initializeReactQuery },
         { default: Toolbar },
      ]) => {
         const toolbarWrapper = document.createElement('div');
         document.body.append(toolbarWrapper);

         const reactRoot = createRoot(toolbarWrapper);
         const fetchFeaturesFn = async () => {
            const response = await fetch('/.well-known/ifixit/flags', {
               credentials: 'include',
            });
            return await response.json();
         };

         const domain = location.hostname.split('.').splice(-2).join('.');

         reactRoot.render(
            initializeReactQuery(
               <ChakraDefaultProvider>
                  <Toolbar
                     fetchFeaturesFn={fetchFeaturesFn}
                     cookieName="featureOverrides"
                     cookieDomain={'.' + domain}
                     header="PHP Feature Flags"
                     branch={toolbar.branch}
                  />
               </ChakraDefaultProvider>
            )
         );
      }
   );
}
